import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

document.addEventListener('DOMContentLoaded', function () {
    const partnersSwiper = new Swiper('.partners_swiper', {
        direction: 'horizontal',
        loop: false,
        spaceBetween: 15,
        modules: [Navigation],
        navigation: {
            nextEl: '.partners-button-next',
            prevEl: '.partners-button-prev',
        },
        breakpoints: {
            480: {
                slidesPerView: 1,
            },
            900: {
                slidesPerView: 2,
            },
            1620: {
                slidesPerView: 3,
            },
        },
    });
});

import lozad from 'lozad';
import LazyLoadScripts from '@otostogan/lazy-script';

import './sliders/partners.js';
import './sliders/post.js';
import './congress/timer.js';
import WOW from 'wowjs';

const observer = lozad();
observer.observe();

document.addEventListener('DOMContentLoaded', () => {
    const burger = document.querySelector('.burger');
    const mobileMenu = document.querySelector('.mobile');
    const searchBtn = document.querySelector('.search-btn');
    const searchOpen = document.querySelector('.search_icon-open');
    const searchClose = document.querySelector('.search_icon-close');
    const searchMenu = document.querySelector('.search');

    searchBtn.addEventListener('click', () => {
        searchOpen.classList.toggle('showIconSearch');
        searchClose.classList.toggle('showIconSearch');
        searchMenu.classList.toggle('showSearchMenu');
    });

    searchMenu.addEventListener('click', (e) => {
        if (e.target === e.currentTarget) {
            e.currentTarget.classList.remove('showSearchMenu');
        }
    });

    if (burger && mobileMenu) {
        burger.addEventListener('click', () => {
            mobileMenu.classList.toggle('show');
            burger.classList.toggle('active');
            document.body.classList.toggle('no-scroll');
        });
    } else {
        console.error('Element not found: .burger or .mobile_menu');
    }

    new WOW.WOW().init();
});

window.addEventListener('scroll', function () {
    const header = document.querySelector('.header');

    if (window.scrollY > 0) {
        header.classList.add('scrolled');
    } else {
        header.classList.remove('scrolled');
    }
});

LazyLoadScripts.run();

import Swiper from 'swiper';
import { Navigation, Thumbs } from 'swiper/modules';
import SimpleLightbox from 'simple-lightbox';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const moreSwiper = new Swiper('.more_swiper', {
    direction: 'horizontal',
    loop: false,
    spaceBetween: 15,
    modules: [Navigation],
    navigation: {
        nextEl: '.more-button-next',
        prevEl: '.more-button-prev',
    },
    breakpoints: {
        17: {
            slidesPerView: 1,
        },
        413: {
            slidesPerView: 2,
        },
        717: {
            slidesPerView: 3,
        },
        900: {
            slidesPerView: 4,
        },

        1000: {
            slidesPerView: 5,
        },
    },
});

new Swiper('.large-swiper', {
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    modules: [Thumbs],
    thumbs: {
        swiper: moreSwiper,
    },
});

new SimpleLightbox({ elements: '.large-gallery a' });
new SimpleLightbox({ elements: '.more-gallery a' });
